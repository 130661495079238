import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogPostRootCanalExpect: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const root_canal_expect_img = data.root_canal_expect_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>General Dentist & the Services they Provide - The Dentists At Gateway Crossing</title>
      <meta name="description" content="If bacteria and decay have reached the pulp of your tooth, you will need a root canal. So, what should you expect when getting a root canal?" />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="If bacteria and decay have reached the pulp of your tooth, you will need a root canal. So, what should you expect when getting a root canal?" />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="If bacteria and decay have reached the pulp of your tooth, you will need a root canal. So, what should you expect when getting a root canal?" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                Root Canal: What to Expect
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2021/10/07
                </li>
                <li className="list-inline-item badge badge-primary">
                  Root Canal
                </li>
                <li className="list-inline-item badge badge-secondary">
                  McCordsville Dentist
                </li>
              </ul>
                <Img fluid={root_canal_expect_img} className="blog-post-img"/>
              {/*<h2 className="mb-3">
                Root canals are often needed when a cavity has been left untreated.
              </h2>*/}
              <p>
              The root of each tooth is connected to your jaw and gums. If bacteria and decay have reached the pulp of your tooth, you will need a root canal. A pocket of infection around the root of your tooth (also known as an abscess) will need to be dealt with in order for you to keep the tooth, eliminate pain, and prevent further damage or infection from hurting any other teeth or parts of your jaw and mouth.</p>
              <p>
              The dentist will clean out the infected area and seal off the canal. Your treated tooth will still be able to function just fine, though it will need to be protected with a crown. 
              </p>
              <p>
              In many cases, a temporary crown or filling is placed while a dental lab creates the new permanent crown that will eventually be placed. In some cases, the dentist may be able to fit you with a crown on that same day.
              </p>
              <p>
              Root canal therapy will almost always result in immediate relief from pain that often accompanies the necessity for the procedure. However, in the immediate aftermath, you are likely to experience some tenderness for a short time afterwards. Your jaw may also be sore from being opened for a prolonged period of time.
              </p>
              <p>
              Any pain or tenderness in the aftermath is often best treated with over the counter meds. However, your dentist will advise you regarding your specific case and in some instances he may even write a prescription for you to get filled, which will be stronger.
              </p>
              <p>
              You should continue practicing good oral hygiene after your root canal. You should brush your teeth twice daily and floss at least once per day in order to maintain a healthy mouth and prevent the development of cavities and other decay that can result in a root canal.
              </p>
              <p>
              In addition, it would be wise to avoid sugary foods and drinks, as well as acidic foods and drinks. These things can weaken your enamel and make it more likely to require root canal therapy. 
              </p>
              <p>
              Cracked and chipped teeth will also increase your risk of needing a root canal in the near future as well. Take good care of your teeth and get them checked out quickly if you damage them. Cavities that are left untreated can develop to the point that they will require a root canal very easily.
              </p>
              <p>
              The vast majority of root canals (more than 95%) are successful. In most instances, people can return to their everyday routines right away after a root canal. However, there are possibilities for complications that you should be aware of. 
              </p>
              <p>
              If you are still experiencing pain after the root canal has been completed, make sure to let the dentist know.
              </p>
              <p>
              Regular check ups with your dentist are always a good idea. Are you in need of a root canal? Are you experiencing severe pain associated with one tooth? This is a classic sign that you may need a root canal, especially if you know of damage that has occurred to that tooth. 
              </p>
              <p>
              Are you in need of a root canal - or even if you are just unsure, give us a call and set up an appointment. We can help you get relief and get back to life. Call today!
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default BlogPostRootCanalExpect

export const query = graphql`
  query BlogPostRootCanalExpectQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    root_canal_expect_img: file(name: { eq: "dental-pain" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
